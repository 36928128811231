import React, {useEffect} from 'react'
import {compose} from 'recompose';
import {inject,observer} from 'mobx-react'
import { Router, Link } from "@reach/router"
import _ from 'lodash'
import DealPage from '@src/components/templates/DealPage'
import withRoot from '../withRoot'
import PropertyDetailPage from '@src/components/templates/propertyAndProjectPage/PropertyDetailPage'
import ImageGallery from '@src/components/templates/propertyAndProjectPage/ImageGallery'
import { useAsyncCall, useUpdateEffect } from 'gx-web-ui/utils/hook'
import { navigate } from "gatsby"
import { hBox,vBox } from "gx-web-ui/utils/flex";
import CircularProgress from '@material-ui/core/CircularProgress';

const Property = (props)=> {
    const {uiStore,appStore,propertyStore,location} = props

    const isBrowser = typeof window !== "undefined"
    const trunks = _.split(_.trimStart(location.pathname,'/'),'/')
    const id = _.nth(trunks,1)
    let { loading, error, result } = useAsyncCall(
        async ()=> await propertyStore.initPropertyDetailDataFromId_(id,false),
        [],
    )
    useEffect(() => {
        uiStore.setExplorePageCurrentTab(null)
    }, [])

    useUpdateEffect(async () => {
        propertyStore.initPropertyDetailDataFromId_(id,false)
    }, [appStore.loggedInUser])

    // console.log(result)

    if(!id && isBrowser){
        navigate('/404')
        return null
    }
    if(error && isBrowser){
        navigate('/property404')
        return null
    }
    
    return (
        loading?
        <div style={{height:'100vh', width:'100vw',...hBox('center','center')}}>
            <CircularProgress color='primary' size={72}/>
        </div>
        :
        <div style={{position:'relative'}}>
            <Router basepath={'/property/:id'}>
                <PropertyDetailPage path="/"/>
                <ImageGallery path="/gallery"/>
            </Router>
        </div>
        )
}

export default compose(withRoot,inject('uiStore','propertyStore','generalPropertyStore','appStore'),observer)(Property)
